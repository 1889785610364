import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";
import { Issuer } from "src/utils/auth";
import axios from "axios";
import { Url } from "src/config";

const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  errorCase:false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
   
    };
  },
  SIGN_IN: (state, action) => {
    const { user,isAuthenticated,errorCase } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      user,
      errorCase:errorCase
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;
/**
 * Authentication Functions
 * signIn Functions, signUp Functions signOut Functions
 * @date 7/14/2023 - 3:08:35 PM
 *
 * @type {*}
 */
export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {

    var user = {}
    try {
      const accessToken = window.sessionStorage.getItem(STORAGE_KEY);
      const data = {token: accessToken}
      if (accessToken) {
        try {
          const response = await axios.post(`${Url}/module/decodeToken`,data);
          user = response.data
          console.log("decoded toekn in Front",response);
        } catch (error) {
          console.log(error.message);
        }
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (data) => {
      var user = {};
      try {
        
        const response = await axios.post(`${Url}/module/login`,data);
        const token = response.headers["x-access-token"];
        const tokenData = {accessToken : token};
        const responseUser = await axios.post(`${Url}/module/decodeToken`,tokenData);
        sessionStorage.setItem(STORAGE_KEY, token);
        user = responseUser.data
        console.log("user",user) ;
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            isAuthenticated: true,
            user
          },
        });
      } catch (error) {
        console.log("error case",error.response);
        const errorMessage = error.response.data.message
        console.log("eroro msg msg",errorMessage);
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            isAuthenticated: false,
            user: null,
            errorCase:errorMessage
          },
        });
     
      }
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (email, name, password) => {
      const { accessToken } = await authApi.signUp({ email, name, password });
      const user = await authApi.me({ accessToken });
      sessionStorage.setItem(STORAGE_KEY, accessToken);
      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    sessionStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const AuthConsumer = AuthContext.Consumer;
