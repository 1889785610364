import { useEffect, useMemo, useState } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import commonApiService from 'src/services/commonApi';

export const useSections = () => {
  const [sideMenus, setSideMenus] = useState([]);


  /**
   * api used to get sideBar menus before rendering this page
   * @date 7/6/2023 - 7:15:10 PM
   */
  function getsidebarmenu() {
    const fetchMenuBar = async () => {
      try {
        const data = await commonApiService.getSideBarMenus();
        setSideMenus(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchMenuBar();
  }

  useEffect(() => {
    getsidebarmenu();
  }, [])

  const menuItems = useMemo(() => {
    if (sideMenus.length === 0) {
      return []; // Return empty array while sideMenus is being fetched
    }

    return sideMenus.map((menu) => ({
      title: menu.menu_text,
      path: menu.menu_link,
      icon: (
        <SvgIcon fontSize="small">
          {menu.menu_iconCls === 'HomeSmileIcon' && <HomeSmileIcon />}
          {menu.menu_iconCls === 'Users03Icon' && <Users03Icon />}
        </SvgIcon>
      ),
      ...(menu.hasOwnProperty("items") && menu.items.length > 0 ? { items: menu.items } : {})
    }));
  }, [sideMenus]);

  return [{ items: menuItems }];
};

