import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/guards/auth-guard';
import { Layout as DashboardLayout } from 'src/layouts/dashboard';
const IndexPage = lazy(() => import('src/pages/dashboard/index'));
// Organizations
const OrganizationsListPage = lazy(() => import('src/pages/organization'));
const OrganizationsDetailPage = lazy(() => import('src/pages/organization/detail'));
const OrganizationsEditPage = lazy(() => import('src/pages/organization/edit'));
const OrganizationsAddPage = lazy(()=> import('src/pages/organization/add'))// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoices/list'));
const InvoiceDetailPage = lazy(() => import('src/pages/dashboard/invoices/detail'));

//CustomerUsers
const CustomerUsersAddPage = lazy(() => import('src/pages/organization/customerUsers/customerUsersAdd'));
const CustomerUsersDetailsPage = lazy(()=>import('src/pages/organization/customerUsers/customerUserDetails'));
const CustomerUserEditPage = lazy(()=>import('src/pages/organization/customerUsers/customerUserEdit'))

// Users
const UserListPage = lazy(() => import('src/pages/users/index'));
const UserDetailPage = lazy(() => import('src/pages/users/detail'));
const UserEditPage = lazy(() => import('src/pages/users/edit'));
const UserAddPage = lazy(() => import('src/pages/users/add'));

//divisions 
const DivisionLIstPage = lazy(() => import('src/pages/organization/division/index'))
const DivisionAddPage = lazy(() => import('src/pages/organization/division/add'))
const DivisionEditPage = lazy(() => import('src/pages/organization/division/edit'))

//subdivisions
const SubDivisionAddPage = lazy(() => import('src/pages/organization/division/subdivision/add'))
const SubDivisionEditPage = lazy(() => import('src/pages/organization/division/subdivision/edit'))
// Role Management


const RoleListPage = lazy(() => import('src/pages/roleManagement/roleList'));
const RoleAddPage  = lazy(() => import('src/pages/roleManagement/add'));
const RoleDetailPage = lazy(() => import('src/pages/roleManagement/details'));
const RoleEditPage = lazy(() => import('src/pages/roleManagement/edit'));

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        path: 'dashboard',
        index: true,
        element: <IndexPage />
      },     
      {
        path: 'organizations',
        children: [
          {
            index: true,
            element: <OrganizationsListPage />
          },
          {
            path: ':customerId',
            element: <OrganizationsDetailPage />
          },
          {
            path: ':customerId/edit',
            element: <OrganizationsEditPage />
          },
          {
            path: 'add',
            element: <OrganizationsAddPage />
          },
          {

            path: ':customerId/customerUsers',
            
            children : [
            
            {
            
            path:'add',
            
            element:<CustomerUsersAddPage/>
            
            },
            {
              path:':cpTenantId',
              element:<CustomerUsersDetailsPage/>
            },
            {
              path:'edit/:cpTenantId',
              element:<CustomerUserEditPage/>
            }
      ]
            
            },
          {
            path:':customerId/:tenantId/divisions',
            children :[
              {
                index:true,
                element: <DivisionLIstPage/>
              },
              {
                path:'add',
                element:<DivisionAddPage/>
              },
              {
                path:':divisionId/edit',
                element:<DivisionEditPage/>
              },
              {
                path:':divisionId/subdivisions',
                children :[
                  {
                    path:'add',
                    element:<SubDivisionAddPage/>
                  },
                  {
                    path:':subdivisionId/edit',
                    element:<SubDivisionEditPage/>
                  },
                ]
            
              },
            
            ]
          },
          
         
        ]
      },
      {
        path: 'users',
        children: [
         
          {
            index: true,
            element: <UserListPage />
          },
          {
            path: 'add',
            element: <UserAddPage/>
          },
          {
            path: ':userId',
            element: <UserDetailPage />
          },
          {
            path: ':userId/edit',
            element: <UserEditPage />
          }
        ]
      },
      {
        path: 'roles',
        children: [
         
          {
            index: true,
            element: <RoleListPage />
          },
          {
            path: 'add',
            element: <RoleAddPage />
          },
          {
            path: ':roleId',
            element: <RoleDetailPage />
          },
          {
            path: ':roleId/edit',
            element: <RoleEditPage />
          }

        ]
      },
      {
        path: 'invoices',
        children: [
          {
            index: true,
            element: <InvoiceListPage />
          },
          {
            path: ':invoiceId',
            element: <InvoiceDetailPage />
          }
        ]
      }
    ]
  }
];
