import axios from "axios";
import { Url } from "src/config";

//Common api

const commonApiService = {
  /**
   * used to get Sidebar menus
   * @returns SideBar menus
   */
  getSideBarMenus: async () => {
    try {
      const response = await axios.get(`${Url}/module/common/getsidebarmenu`);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  },
};

export default commonApiService;
